<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';

import type { FormResult } from '~ui/types/formResult';

interface SubmitButtonVariant {
  variant?: 'primary' | 'outlined';
  isFullwidth?: boolean;
  position?: 'left' | 'center' | 'right';
  disabled?: boolean;
}
interface Props {
  submitButtonText: string;
  buttonVariant?: SubmitButtonVariant;
  hideSubmitButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hideSubmitButton: false,
  buttonVariant: () => ({
    isFullwidth: true,
    variant: 'primary',
    position: 'center',
    disabled: false,
  }),
});

const emit = defineEmits<{
  submit: [() => void, (newResult: FormResult) => void, () => void];
}>();

const validation = useVuelidate();

const loading = ref<boolean>(false);

const result = ref<FormResult | null>(null);

const doneLoading = () => {
  loading.value = false;
};

const setResult = (newResult: FormResult) => {
  result.value = newResult;
};

const clearResult = () => {
  result.value = null;
};

const resetValidationErrors = () => {
  validation.value.$reset();
};

const submitForm = async () => {
  clearResult();
  resetValidationErrors();
  loading.value = true;
  if (!(await validation.value.$validate())) {
    loading.value = false;
    return;
  }

  emit('submit', doneLoading, setResult, clearResult);
};

const buttonContainerClasses = computed<string>(() =>
  twMerge(
    cva('mt-5 flex items-center', {
      variants: {
        position: {
          center: 'justify-center',
          left: 'justify-start',
          right: 'justify-end',
        },
      },
    })({
      position: props.buttonVariant.position,
    }),
  ),
);

const buttonClasses = computed<string>(() =>
  twMerge(
    cva('', {
      variants: {
        fullwidth: {
          true: 'w-full',
        },
      },
    })({
      fullwidth: props.buttonVariant.isFullwidth,
    }),
  ),
);

const shouldDisableSubmit = computed(() => validation.value.$silentErrors.length > 0);
</script>

<template>
  <div>
    <form @submit.prevent="submitForm">
      <div class="space-y-2">
        <slot :loading="loading" :resetValidationErrors="resetValidationErrors" />
      </div>
      <div v-show="!hideSubmitButton" :class="buttonContainerClasses">
        <LazyUIButton
          :text="submitButtonText"
          :variant="buttonVariant.variant"
          type="submit"
          :loading="loading"
          :customClasses="buttonClasses"
          :disabled="buttonVariant.disabled || shouldDisableSubmit"
        />
      </div>
      <UITransitionFadeIn>
        <UIAlertMessage v-if="result" :message="result.message" :variant="result.variant" class="mt-4" @clear="clearResult" />
      </UITransitionFadeIn>
    </form>
  </div>
</template>
